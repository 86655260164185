import type { SiteIdData } from './siteId';

export const hostsProduction = {
  sonara: 'sonara.ai',
};

export const hostsStaging = {
  sonara: 'stg.sonara.ai',
};

export const hostsTesting = {
  interviewme: 'app2.testing.modularfeatures.interviewme.pl',
  livecareer: 'app2.testing.modularfeatures.livecareer.com',
  livecareerbr: 'app2.testing.modularfeatures.livecareer.com.br',
  livecareeres: 'app2.testing.modularfeatures.livecareer.es',
  livecareerfr: 'app2.testing.modularfeatures.livecareer.fr',
  livecareerit: 'app2.testing.modularfeatures.livecareer.it',
  livecareermx: 'app2.testing.modularfeatures.livecareer.mx',
  livecareernl: 'app2.testing.modularfeatures.livecareer.nl',
  livecareerpl: 'app2.testing.modularfeatures.livecareer.pl',
  livecareerpt: 'app2.testing.modularfeatures.livecareer.pt',
  livecareeruk: 'app2.testing.modularfeatures.livecareer.co.uk',
  moncvparfait: 'app2.testing.modularfeatures.moncvparfait.fr',
  myperfectresume: 'app2.testing.modularfeatures.myperfectresume.com',
  myperfectresumebr: 'app2.testing.modularfeatures.meucurriculoperfeito.com.br',
  myperfectresumede: 'app2.testing.modularfeatures.meinperfekterlebenslauf.de',
  myperfectresumees: 'app2.testing.modularfeatures.micvideal.es',
  myperfectresumeit: 'app2.testing.modularfeatures.ilcvperfetto.it',
  myperfectresumemx: 'app2.testing.modularfeatures.micvideal.mx',
  myperfectresumeuk: 'app2.testing.modularfeatures.myperfectcv.co.uk',
  resumelab: 'app2.testing.modularfeatures.resumelab.com',
  resumenow: 'app2.testing.modularfeatures.resume-now.com',
  sonara: 'app2.testing.modularfeatures.sonara-sb.com', // TODO XXX is it final?
  zetycom: 'app2.testing.modularfeatures.zety.com',
  zetyde: 'app2.testing.modularfeatures.zety.de',
  zetydk: 'app2.testing.modularfeatures.zety.dk',
  zetyes: 'app2.testing.modularfeatures.zety.es',
  zetyfr: 'app2.testing.modularfeatures.zety.fr',
  zetyit: 'app2.testing.modularfeatures.zety.it',
  zetyse: 'app2.testing.modularfeatures.zety.se',
} as const satisfies Partial<SiteIdData<string>>;

export const hostsAdditionalTesting = {
  sonara: {
    qa: 'qa.sonara-sb.com',
    reg: 'reg.sonara-sb.com',
  },
} as const satisfies Partial<SiteIdData<Dictionary | string>>;

export const hostsLocal = {
  interviewme: 'local.interviewme.pl',
  livecareer: 'local.livecareer.com',
  livecareerbr: 'local.livecareer.com.br',
  livecareeres: 'local.livecareer.es',
  livecareerfr: 'local.livecareer.fr',
  livecareerit: 'local.livecareer.it',
  livecareermx: 'local.livecareer.mx',
  livecareernl: 'local.livecareer.nl',
  livecareerpl: 'local.livecareer.pl',
  livecareerpt: 'local.livecareer.pt',
  livecareeruk: 'local.livecareer.co.uk',
  moncvparfait: 'local.moncvparfait.fr',
  myperfectresume: 'local.myperfectresume.com',
  myperfectresumebr: 'local.meucurriculoperfeito.com.br',
  myperfectresumede: 'local.meinperfekterlebenslauf.de',
  myperfectresumees: 'local.micvideal.es',
  myperfectresumeit: 'local.ilcvperfetto.it',
  myperfectresumemx: 'local.micvideal.mx',
  myperfectresumeuk: 'local.myperfectcv.co.uk',
  resumelab: 'local.resumelab.com',
  resumenow: 'local.resume-now.com',
  sonara: 'local.sonara-sb.com', // TODO XXX is it final?
  zetycom: 'local.zety.com',
  zetyde: 'local.zety.de',
  zetydk: 'local.zety.dk',
  zetyes: 'local.zety.es',
  zetyfr: 'local.zety.fr',
  zetyit: 'local.zety.it',
  zetyse: 'local.zety.se',

  // additional based on removed AVAILABLE_LOCAL_HOSTS_FT
  livecareerde: 'local.livecareer.de',
  livecareerdk: 'local-dk.livecareer.com',
  livecareerse: 'local-se.livecareer.com',
  zetybr: 'local.zety.br',
} as const satisfies Partial<SiteIdData<string>>;

export const hostTestingPrefix = 'app2.testing.modularfeatures';

export const hostLocalPrefix = 'local';

export const hostDefaultTesting = `${hostTestingPrefix}.workz.pl`;

export const hostsTestingValues = Object.values(hostsTesting);

export const hostsLocalValues = Object.values(hostsLocal);
