/**
 * Simple service to log out console messages
 */

import { LOCAL_STORAGE_KEYS } from '../constants/localStorage';

const showLogs =
  localStorage.getItem(LOCAL_STORAGE_KEYS.devShowLogs) === 'true' || process.env.MF_IS_STORYBOOK === 'true';

const printToConsole = (
  {
    type = 'log',
    context = 'APP',
    color = 'initial',
    icon,
  }: {
    color?: string;
    context?: string;
    icon?: string;
    type?: 'log' | 'warn' | 'error';
  } = {},
  ...args: unknown[]
) => {
  // eslint-disable-next-line no-console
  console[type]('%c' + `${icon ?? ''} ${context}${args.length ? ':' : ''}`.trim(), `color: ${color}`, ...args);
};

const log = (context: string, ...args: unknown[]) =>
  showLogs && printToConsole({ color: 'deepskyblue', context, icon: '🪵' /* log, xD */ }, ...args);
const success = (context: string, ...args: unknown[]) =>
  showLogs && printToConsole({ color: 'limegreen', context, icon: '🏆' }, ...args);
const warn = (context: string, ...args: unknown[]) =>
  showLogs && printToConsole({ context, icon: '👮🏻‍♂️', type: 'warn' }, ...args);
const error = (context: string, ...args: unknown[]) =>
  showLogs && printToConsole({ context, icon: '⛔️', type: 'error' }, ...args);
const hot = (context: string, ...args: unknown[]) =>
  showLogs && printToConsole({ color: 'hotpink', context, icon: '🔥' }, ...args);
const grey = (context: string, ...args: unknown[]) => {
  if (!showLogs) {
    return;
  }
  if (args.length === 0) {
    printToConsole({ color: 'grey', context });

    return;
  }
  /* eslint-disable no-console */
  console.groupCollapsed('%c' + context, 'color: grey');
  console.log(...args);
  console.groupEnd();
  /* eslint-enable no-console */
};

const loggerService = { error, grey, hot, log, success, warn };

export type LoggerService = typeof loggerService;

export default loggerService;
