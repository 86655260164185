import type { ConfigSiteTokens } from '../../types';

import deactivateLink from '../../../../assets/images/feedback/NGB/deactivateLink.svg';
import feedbackerOnboardingImage1 from '../../../../assets/images/feedback/NGB/feedbackerOnBoardingImage1.svg';
import feedbackerOnboardingImage2 from '../../../../assets/images/feedback/NGB/feedbackerOnBoardingImage2.svg';
import feedbackerOnboardingImage3 from '../../../../assets/images/feedback/NGB/feedbackerOnBoardingImage3.svg';
import feedbackerOnboardingImage4 from '../../../../assets/images/feedback/NGB/feedbackerOnBoardingImage4.svg';
import modalOwnerOnBoardingWelcome from '../../../../assets/images/feedback/NGB/modalOwnerOnBoardingWelcome.svg';
import noNewComments from '../../../../assets/images/feedback/NGB/noNewComments.svg';
import sessionInactive from '../../../../assets/images/feedback/NGB/sessionInactive.svg';
import bot from '../../../../assets/images/interviewcheck/NGB/botIcon.svg';
import hero from '../../../../assets/images/interviewcheck/NGB/heroImage.svg';

import iconArrowLeft from '../../../../svg/feedback/NGB/iconArrowLeft';
import iconArrowRight from '../../../../svg/feedback/NGB/iconArrowRight';
import iconArrowUp from '../../../../svg/feedback/NGB/iconArrowUp';
import iconAvatar from '../../../../svg/feedback/NGB/iconAvatar';
import iconAvatarNoBorder from '../../../../svg/feedback/NGB/iconAvatarNoBorder';
import iconBin from '../../../../svg/feedback/NGB/iconBin';
import iconChecked from '../../../../svg/feedback/NGB/iconChecked';
import iconChevron from '../../../../svg/feedback/NGB/iconChevron';
import iconClose from '../../../../svg/feedback/NGB/iconClose';
import iconComments from '../../../../svg/feedback/NGB/iconComments';
import iconDocument from '../../../../svg/feedback/NGB/iconDocument';
import iconEdit from '../../../../svg/feedback/NGB/iconEdit';
import iconError from '../../../../svg/feedback/NGB/iconError';
import iconHamburger from '../../../../svg/feedback/NGB/iconHamburger';
import iconHelp from '../../../../svg/feedback/NGB/iconHelp';
import iconInfo from '../../../../svg/feedback/NGB/iconInfo';
import iconLink from '../../../../svg/feedback/NGB/iconLink';
import iconLoader from '../../../../svg/feedback/NGB/iconLoader';
import iconLocationPin from '../../../../svg/feedback/NGB/iconLocationPin';
import iconLocationPinWithCharacter from '../../../../svg/feedback/NGB/iconLocationPinWithCharacter';
import iconSuccess from '../../../../svg/feedback/NGB/iconSuccess';
import iconTick from '../../../../svg/feedback/NGB/iconTick';
import iconArrowRightArrowLeft from '../../../../svg/general/iconArrowRightArrowLeft';
import iconChevronUpSmall from '../../../../svg/general/iconChevronUpSmall';
import iconCv from '../../../../svg/general/iconCv';

export const configSiteTokens: ConfigSiteTokens = {
  common: {
    sizes: {
      modalButtonClose: 1.6,
    },
  },

  aiInterview: {
    advancedSelector: {
      chevronIcon: iconChevronUpSmall,
      chevronIconSize: 1.6,
      dropdownLabelDisplayStyle: 'paragraphMedium',
      dropdownSubLabelDisplayStyle: 'paragraphSmall',
    },
    autoComplete: {
      boldTextOption: 'normal',
    },
    availableInterviewLanguages: ['en-US'],
    displayStyles: {
      buttons: {
        confirmFinish: 'quaternary',
        finishInterview: 'tertiary',
        globalTryAgain: 'primary',
        hamburgerMenu: 'tertiary',
        rateConversation: 'tertiary',
        sendMessage: 'secondary',
        topbar: 'tertiary',
        willUseInFuture: 'tertiary',
      },
      text: {
        headerToolName: 'h5',
        interviewData: 'paragraphSmall',
        interviewSettings: 'h4',
        interviewSettingsItem: 'h5',
        mainHeader: 'h3',
        mainSubheader: 'paragraphLarge',
        message: 'paragraphLarge',
        messageLegal: 'paragraphSmall',
        modalDescription: 'paragraphLarge',
        progressBarHeader: 'paragraphMedium',
        progressBarSubheader: 'paragraphMedium',
        scoring: 'paragraphMedium',
        surveyHeader: 'h4',
        tip: 'paragraphSmall',
        toolName: 'h4',
      },
    },
    icons: {
      arrowLeft: iconArrowLeft,
    },
    images: {
      bot,
      hero,
      iconCv,
    },
    sizes: {
      buttons: {
        backButton: 'small',
        finishInterviewInHeader: 'small',
        reportProblemInTopbar: 'small',
      },
    },
    visibility: {
      goToTipsButton: true,
      topbarUserMenu: true,
    },
  },
  expertApply: {
    advancedSelector: {
      chevronIcon: iconChevronUpSmall,
      chevronIconSize: 2.4,
      dropdownLabelDisplayStyle: 'bodyDefault',
      dropdownSubLabelDisplayStyle: 'bodySmall',
    },
    autoComplete: {
      boldTextOption: 'normal',
    },
  },
  feedback: {
    animations: {
      loader: true,
    },
    displayStyles: {
      buttons: {
        feedbackerDeleteComment: 'tertiary',
        feedbackerOnboardingPrimaryButton: 'primary',
        feedbackerOnboardingSecondaryButton: 'quaternary',
        feedbackerOwnComments: 'tertiary',
        feedbackerSendOrEditComment: 'secondary',
        feedbackerSessionInactive: 'secondary',
        globalModalError: 'primary',
        globalTryAgain: 'primary',
        ownerChangeDocument: 'quaternary',
        ownerCommentMarkAsRead: 'tertiary',
        ownerDeactivateLinkModalConfirmation: 'primary',
        ownerMainBack: 'tertiary',
        ownerMainEditDocument: 'tertiary',
        ownerMainGenerateLink: 'primary',
        ownerMainOnboarding: 'tertiary',
        ownerShareLink: 'secondary',
        ownerShareModalDesktopCopyLink: 'secondary',
        ownerShareModalMobileCopyLink: 'secondary',
        ownerShareModalMobileShareVia: 'primary',
        ownerWelcomeModalShareLink: 'quaternary',
        ownerWelcomeModalShowTutorial: 'primary',
      },
      text: {
        changeDocumentMobileHeader: 'h6',
        commentDate: 'paragraphSmall',
        commentHeader: 'h6',
        commentText: 'paragraphMedium',
        contentWithLabelHeader: 'h4',
        contentWithLabelText: 'paragraphSmall',
        copyrightInfo: 'paragraphSmall',
        documentName: 'h4',
        editCommentHeader: 'paragraphMedium',
        hamburgerMenuHeader: 'h4',
        hamburgerMenuText: 'paragraphLarge',
        modalText: 'paragraphLarge',
        modalTitleDesktop: 'h3',
        modalTitleMobile: 'h3',
        nicknameLabel: 'paragraphMedium',
        sessionInactiveText: 'paragraphLarge',
      },
    },
    icons: {
      arrowLeft: iconArrowLeft,
      arrowRight: iconArrowRight,
      arrowRightArrowLeft: iconArrowRightArrowLeft,
      arrowUp: iconArrowUp,
      avatar: iconAvatar,
      chevron: iconChevron,
      close: iconClose,
      commentTemplateAvatar: iconAvatarNoBorder,
      deleteComment: iconBin,
      document: iconDocument,
      edit: iconEdit,
      error: iconError,
      hamburger: iconHamburger,
      help: iconHelp,
      info: iconInfo,
      link: iconLink,
      loader: iconLoader,
      markAsRead: iconChecked,
      newPin: iconLocationPin,
      onboardingButton: iconHelp,
      ownCommentsButton: iconComments,
      pin: iconLocationPinWithCharacter,
      success: iconSuccess,
      tick: iconTick,
    },
    images: {
      deactivateLink,
      feedbackerOnboardingImage1,
      feedbackerOnboardingImage2,
      feedbackerOnboardingImage3,
      feedbackerOnboardingImage4,
      modalOwnerOnBoardingWelcome,
      noNewComments,
      sessionInactive,
    },
    sizes: {
      buttonClose: 'small',
      commentButton: 'medium',
      commentTemplateAvatar: 3.2,
      deactivateImageHeight: 248,
      deactivateImageWidth: 274,
      hamburgerMenuButton: 'large',
      iconDocument: 2,
      iconInButton: {
        large: 1.6,
        medium: 1.6,
        small: 1.6,
      },
      modalButton: 'medium',
      modalErrorButton: 'medium',
      sessionInactiveButton: 'medium',
      shareModalMainButton: 'large',
      swipeableContentButton: 'large',
      topbarButton: 'small',
    },
    visibility: {
      expandableCommentsTag: true,
      modalErrorButton: false,
      modalErrorFooter: true,
      oboardingBackButtonLabel: false,
      onboardingTooltipIcons: true,
    },
  },
};
