import type { SiteIdValues } from '../constants/siteId';

import { hostsAdditionalTesting, hostsLocal, hostsProduction, hostsStaging, hostsTesting } from '../constants/hosts';

const reverseHostsProduction = Object.fromEntries(Object.entries(hostsProduction).map(([key, value]) => [value, key]));
const reverseHostsStaging = Object.fromEntries(Object.entries(hostsStaging).map(([key, value]) => [value, key]));
const reverseHostsTesting = Object.fromEntries(Object.entries(hostsTesting).map(([key, value]) => [value, key]));
const reverseHostsLocal = Object.fromEntries(Object.entries(hostsLocal).map(([key, value]) => [value, key]));
const reverseHostsAdditionalTesting = Object.fromEntries(
  Object.entries(hostsAdditionalTesting).flatMap(([key, value]) => {
    if (typeof value === 'string') {
      return [[value, key]];
    }

    return Object.values(value).map((v) => [v, key]);
  }),
);
const hostnameToSiteId = {
  ...reverseHostsTesting,
  ...reverseHostsLocal,
  ...reverseHostsAdditionalTesting,
  ...reverseHostsStaging,
  ...reverseHostsProduction,
} as Partial<Record<string, SiteIdValues>>;

export function getSiteIdFromUrl(hostname = window.location.hostname, data = hostnameToSiteId) {
  const siteId = data[hostname];

  return siteId;
}
