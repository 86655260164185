// import '@boldpl/common/entry/promiseFix';

import hideInitLoader from '@boldpl/common/entry/hideInitLoader';
import rewriteUrlOnInit from '@boldpl/common/entry/rewriteUrlOnInit';
import { loadPolyfillsLight } from '@boldpl/common/polyfill';

import { configSiteLoaded } from '@boldpl/common/config/site/configSiteLoaded';
import { configSiteTokens } from '@boldpl/common/config/site/tokens/variant/ngb';

(async () => {
  await loadPolyfillsLight();

  Object.assign(configSiteLoaded, {
    siteIdData: { name: 'Embed Features' },
    tokens: {
      ...configSiteTokens,
    },
  });

  const renderNode = window.document.querySelector('#mf-features');

  if (!renderNode || !(renderNode instanceof HTMLElement)) {
    return;
  }

  renderNode.dataset.mfEmbed = ''; // data-mf-embed selector for CSS variables
  rewriteUrlOnInit();

  const { mountApp } = await import('./mountApp');

  hideInitLoader('#mf-features-init-loader'); // not '#mf-init-loader' so elements don't colide

  mountApp(renderNode);
})();
