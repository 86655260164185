import { createBrowserHistory } from 'history';

import type { History } from 'history';

import loggerService from '../services/loggerService';

import { LOCAL_STORAGE_KEYS } from '../constants/localStorage';

// TODO XXX cleanup
export interface HistoryState {
  customBackUrl?: string;
  from?: string;
  hasResumes?: boolean;
  is404?: boolean;
  is404From?: string;
  isRecommendationClicked?: boolean;
  redirectAfterLanguageChange?: string;
  redirectToAfterAuth?: string;
  resetPasswordEmail?: string;
  shouldDisplayCustomPromoPlan?: boolean;
}

// react router's history used in our app
const history: History = createBrowserHistory();

if (process.env.NODE_ENV === 'development' || window.navigator.webdriver) {
  window.mf = window.mf || {};
  window.mf.history = history;
}

if (localStorage?.getItem(LOCAL_STORAGE_KEYS.navDebug) === 'true') {
  history.listen(({ location }) => {
    loggerService.log(
      'Navigation',
      `${location.pathname}${location.search}${location.hash}`,
      location.state ? JSON.stringify(location.state) : '',
    );
  });
}

export default history;
